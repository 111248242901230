.App {
 /* text-align: center; */
 /* focus on mobile smartphone first */
 display: flex;
 flex-direction: column;
 /* border: 5px solid red; */
}

.base {
 /* text-align: center; */
 /* focus on mobile smartphone first */
 max-width: 540px;
 min-height: 100vh;
 margin: 0 auto;
 /* border: 5px solid red; */
}

.content {
 flex: 1 1 auto;
 min-height: auto;
 display: flex;
 flex-direction: column;
 /* margin: 0 10px; */
 /* margin-bottom: 100px; */
 /* padding: 70px 2px; */
 /* border: 5px solid red; */
}

.toolb {
 border: 5px solid purple;
}

/* .App > * {
} */

*/ .Logo-header {
 height: 8vh;
}

.icon-bar {
 display: flex;
}

.icon-bar a {
 flex: 1;
}
